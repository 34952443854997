<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 22.5 15.75">
    <path
      :style="iconStyle"
      d="M18.914-7.77a4.033,4.033,0,0,1,1.828.809,4.58,4.58,0,0,1,1.283,1.582,4.423,4.423,0,0,1,.475,2A4.336,4.336,0,0,1,21.182-.193,4.336,4.336,0,0,1,18,1.125H5.063A4.882,4.882,0,0,1,1.477-.352,4.882,4.882,0,0,1,0-3.937,4.884,4.884,0,0,1,.949-6.891,5.05,5.05,0,0,1,3.375-8.719V-9a5.417,5.417,0,0,1,1.652-3.973A5.417,5.417,0,0,1,9-14.625a5.47,5.47,0,0,1,2.848.773,5.668,5.668,0,0,1,2.039,2.039,3.288,3.288,0,0,1,1.863-.562,3.255,3.255,0,0,1,2.391.984A3.255,3.255,0,0,1,19.125-9,3.671,3.671,0,0,1,18.914-7.77Zm-5.1,2.145a.522.522,0,0,0,.527-.352.511.511,0,0,0-.105-.6L10.512-10.3a.483.483,0,0,0-.773,0L6.012-6.574a.511.511,0,0,0-.105.6.522.522,0,0,0,.527.352H8.719v3.938a.548.548,0,0,0,.158.4.548.548,0,0,0,.4.158h1.688a.548.548,0,0,0,.4-.158.548.548,0,0,0,.158-.4V-5.625Z"
      transform="translate(0 14.625)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '15.75',
    },
    width: {
      type: String,
      default: '22.5',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
